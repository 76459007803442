var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c("div", { staticClass: "admin-edit-data-link" }, [
        _c("div", { staticClass: "flex-row" }, [
          _vm._m(0),
          _c("div", { staticClass: "col-6" }, [
            _c("label", { attrs: { for: "name" } }, [_vm._v("Link Code *")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.linkData.link_code,
                  expression: "linkData.link_code"
                }
              ],
              staticClass: "w-100",
              attrs: {
                type: "text",
                maxlength: "256",
                name: "name",
                "data-name": "name",
                placeholder: "Link Code (Blank for Auto)",
                id: "name"
              },
              domProps: { value: _vm.linkData.link_code },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.linkData, "link_code", $event.target.value)
                  },
                  function($event) {
                    _vm.isDirty = true
                  }
                ]
              }
            })
          ]),
          _c("div", { staticClass: "col-6" }, [
            _c("label", { attrs: { for: "name" } }, [_vm._v("Name")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.linkData.name,
                  expression: "linkData.name"
                }
              ],
              staticClass: "w-100",
              attrs: {
                type: "text",
                maxlength: "256",
                name: "name",
                "data-name": "name",
                placeholder: "Link Name",
                id: "name"
              },
              domProps: { value: _vm.linkData.name },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.linkData, "name", $event.target.value)
                  },
                  function($event) {
                    _vm.isDirty = true
                  }
                ]
              }
            })
          ]),
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("label", { attrs: { for: "description" } }, [
                _vm._v("Activity Type")
              ]),
              _c("b-select", {
                staticClass: "w-100",
                attrs: { options: _vm.activityTypes },
                on: { input: _vm.changeType },
                model: {
                  value: _vm.linkData.activity_type,
                  callback: function($$v) {
                    _vm.$set(_vm.linkData, "activity_type", $$v)
                  },
                  expression: "linkData.activity_type"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-4" },
            [
              _c("label", { attrs: { for: "owner_id" } }, [
                _vm._v("Is Published?")
              ]),
              _c(
                "b-form-checkbox",
                {
                  attrs: {
                    checked: _vm.linkData.published,
                    "aria-describedby": "devices",
                    "aria-controls": "devices"
                  },
                  on: {
                    change: function($event) {
                      _vm.isDirty = true
                    }
                  }
                },
                [_vm._v(" Published ")]
              )
            ],
            1
          ),
          _c("div", { staticClass: "col-6" }, [
            _c("label", { attrs: { for: "owner_id" } }, [_vm._v("Owner Id")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.linkData.owner_id,
                  expression: "linkData.owner_id"
                }
              ],
              staticClass: "w-100",
              attrs: {
                type: "text",
                maxlength: "256",
                name: "name",
                "data-name": "name",
                placeholder: "Owner Id",
                id: "owner_id"
              },
              domProps: { value: _vm.linkData.owner_id },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.linkData, "owner_id", $event.target.value)
                  },
                  function($event) {
                    _vm.isDirty = true
                  }
                ]
              }
            })
          ]),
          _c(
            "div",
            {
              staticClass: "col-12",
              class: { "expand-section": _vm.expandData }
            },
            [
              _c("div", { staticClass: "flex-row justify-content-between" }, [
                _c("div", { staticClass: "flex-row justify-content-center" }, [
                  _c("label", { attrs: { for: "sim_iccid" } }, [
                    _vm._v("Data Link Payload JSON")
                  ]),
                  !_vm.isDataValid
                    ? _c("div", { staticClass: "invalid-warning" }, [
                        _vm._v("Invalid JSON "),
                        _c("i", { staticClass: "fa fa-exclamation-triangle" })
                      ])
                    : _vm._e()
                ]),
                _c("i", {
                  staticClass: "fa icon-button",
                  class: {
                    "fa-expand": !_vm.expandData,
                    "fa-compress": _vm.expandData
                  },
                  on: {
                    click: function($event) {
                      _vm.expandData = !_vm.expandData
                    }
                  }
                })
              ]),
              _c(
                "div",
                {
                  staticClass: "schema-editor",
                  class: {
                    "invalid-data": !_vm.isDataValid,
                    "expand-editor": _vm.expandData
                  }
                },
                [
                  _c("codemirror", {
                    ref: "jsonEditor",
                    attrs: { options: _vm.cmOptions },
                    on: { input: _vm.changeData },
                    model: {
                      value: _vm.dataText,
                      callback: function($$v) {
                        _vm.dataText = $$v
                      },
                      expression: "dataText"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c("div", { staticClass: "col-6" }, [
            _c("label", { attrs: { for: "created_time" } }, [
              _vm._v("Created")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.linkData.created,
                  expression: "linkData.created"
                }
              ],
              staticClass: "w-100",
              attrs: {
                type: "text",
                disabled: "",
                maxlength: "256",
                name: "Registration",
                "data-name": "Registration",
                placeholder: "Created Time",
                id: "created_time"
              },
              domProps: { value: _vm.linkData.created },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.linkData, "created", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "col-6" }, [
            _c("label", { attrs: { for: "updated_time" } }, [
              _vm._v("Updated")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.linkData.updated,
                  expression: "linkData.updated"
                }
              ],
              staticClass: "w-100",
              attrs: {
                type: "text",
                disabled: "",
                maxlength: "256",
                name: "Registration",
                "data-name": "Registration",
                placeholder: "Updated Time",
                id: "updated_time"
              },
              domProps: { value: _vm.linkData.updated },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.linkData, "updated", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "col-6" }, [
            _c("label", { attrs: { for: "datalink_url" } }, [
              _vm._v("Datalink URL")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.linkData.URL,
                  expression: "linkData.URL"
                }
              ],
              staticClass: "w-100",
              attrs: {
                type: "text",
                disabled: "",
                maxlength: "256",
                placeholder: "Datalink URL",
                id: "datalink_url"
              },
              domProps: { value: _vm.linkData.URL },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.linkData, "URL", $event.target.value)
                }
              }
            })
          ]),
          _vm._m(1),
          _c("div", { staticClass: "col-12 row" }, [
            _c("div", { staticClass: "col-6" }, [
              _c("div", [
                _c("label", { attrs: { for: "qrCodeSize" } }, [
                  _vm._v("Qr Code Size")
                ]),
                _vm._v(" " + _vm._s(_vm.qrCodeSize) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.qrCodeSize,
                      expression: "qrCodeSize"
                    }
                  ],
                  staticClass: "w-100",
                  attrs: {
                    type: "range",
                    min: "10",
                    max: "500",
                    id: "qrCodeSize"
                  },
                  domProps: { value: _vm.qrCodeSize },
                  on: {
                    __r: function($event) {
                      _vm.qrCodeSize = $event.target.value
                    }
                  }
                })
              ]),
              _c(
                "div",
                [
                  _c("label", { attrs: { for: "qrCodeRender" } }, [
                    _vm._v("QR Render Mode")
                  ]),
                  _c(
                    "b-select",
                    {
                      attrs: { id: "qrCodeRender" },
                      model: {
                        value: _vm.renderQRas,
                        callback: function($$v) {
                          _vm.renderQRas = $$v
                        },
                        expression: "renderQRas"
                      }
                    },
                    [
                      _c("b-form-select-option", { attrs: { value: "svg" } }, [
                        _vm._v("SVG")
                      ]),
                      _c(
                        "b-form-select-option",
                        { attrs: { value: "canvas" } },
                        [_vm._v("Canvas")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("label", { attrs: { for: "qrErrorMode" } }, [
                    _vm._v("QR Render Error Level")
                  ]),
                  _c(
                    "b-select",
                    {
                      attrs: { id: "qrErrorMode" },
                      model: {
                        value: _vm.qrErrorLevel,
                        callback: function($$v) {
                          _vm.qrErrorLevel = $$v
                        },
                        expression: "qrErrorLevel"
                      }
                    },
                    [
                      _c("b-form-select-option", { attrs: { value: "L" } }, [
                        _vm._v("L - 7%")
                      ]),
                      _c("b-form-select-option", { attrs: { value: "M" } }, [
                        _vm._v("M - 15%")
                      ]),
                      _c("b-form-select-option", { attrs: { value: "Q" } }, [
                        _vm._v("Q - 25%")
                      ]),
                      _c("b-form-select-option", { attrs: { value: "H" } }, [
                        _vm._v("H - 30%")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "col-6" }, [
              _c("label", { attrs: { for: "datalink_url" } }, [
                _vm._v("QR Code")
              ]),
              _vm.linkData.URL
                ? _c(
                    "div",
                    [
                      _c("QrcodeVue", {
                        ref: "qrCode",
                        attrs: {
                          value: _vm.linkData.URL,
                          size: _vm.qrCodeSize,
                          level: _vm.qrErrorLevel,
                          "render-as": _vm.renderQRas
                        }
                      }),
                      _vm.renderQRas === "svg"
                        ? _c("button", { on: { click: _vm.downloadSVG } }, [
                            _vm._v("Download SVG")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _c("div", [_vm._v("QR Code will appear here.")])
            ])
          ])
        ]),
        _c("div", { staticClass: "flex-row justify-content-end mt-2" }, [
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isDirty,
                  expression: "isDirty"
                }
              ],
              staticClass: "warning",
              on: { click: _vm.revertChanges }
            },
            [_vm._v("Revert Changes")]
          ),
          _c(
            "button",
            {
              attrs: { disabled: !_vm.isDirty || !_vm.isDataValid },
              on: { click: _vm.clickSave }
            },
            [_vm._v("Save")]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("h3", { staticClass: "mt-3" }, [_vm._v("Link Data Settings")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("h3", { staticClass: "mt-3" }, [_vm._v("QR Code Settings")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }