<template>
  <div class="admin-edit-data-link" v-if="!loading">
    <div class="flex-row">
      <div class="col-12">
        <h3 class="mt-3">Link Data Settings</h3>
      </div>
      <div class="col-6">
        <label for="name">Link Code *</label>
        <input type="text" v-model="linkData.link_code" class="w-100"
               maxlength="256" name="name" data-name="name" placeholder="Link Code (Blank for Auto)" id="name"
               @input="isDirty=true"
        />

      </div>
      <div class="col-6">
        <label for="name">Name</label>
        <input type="text" v-model="linkData.name" class="w-100"
               maxlength="256" name="name" data-name="name" placeholder="Link Name" id="name"
               @input="isDirty=true"
        />
      </div>
      <div class="col-6">
        <label for="description">Activity Type</label>
        <b-select :options="activityTypes" v-model="linkData.activity_type" class="w-100"
                  @input="changeType"
        ></b-select>
      </div>

      <div class="col-4">
        <label for="owner_id">Is Published?</label>
        <b-form-checkbox
          :checked="linkData.published"
          aria-describedby="devices"
          aria-controls="devices"
          @change="isDirty=true"
        >
          Published
        </b-form-checkbox>
      </div>

      <div class="col-6">
        <label for="owner_id">Owner Id</label>
        <input type="text" v-model="linkData.owner_id" class="w-100"
               maxlength="256" name="name" data-name="name" placeholder="Owner Id" id="owner_id"
               @input="isDirty=true"
        />
      </div>

      <div class="col-12" :class="{'expand-section': expandData}">
        <div class="flex-row justify-content-between">
          <div class="flex-row justify-content-center">
            <label for="sim_iccid">Data Link Payload JSON</label>
            <div v-if="!isDataValid" class="invalid-warning">Invalid JSON <i class="fa fa-exclamation-triangle"></i>
            </div>
          </div>
          <i class="fa icon-button" :class="{'fa-expand': !expandData, 'fa-compress': expandData}"
             @click="expandData = !expandData"></i>
        </div>
        <div class="schema-editor" :class="{'invalid-data': !isDataValid, 'expand-editor': expandData}">
          <codemirror ref="jsonEditor" v-model="dataText" :options="cmOptions" @input="changeData"
          ></codemirror>
        </div>
      </div>
      <div class="col-6">
        <label for="created_time">Created</label>
        <input type="text" v-model="linkData.created" class="w-100" disabled
               maxlength="256" name="Registration" data-name="Registration" placeholder="Created Time"
               id="created_time">
      </div>
      <div class="col-6">
        <label for="updated_time">Updated</label>
        <input type="text" v-model="linkData.updated" class="w-100" disabled
               maxlength="256" name="Registration" data-name="Registration" placeholder="Updated Time"
               id="updated_time">
      </div>
      <div class="col-6">
        <label for="datalink_url">Datalink URL</label>
        <input type="text" v-model="linkData.URL" class="w-100" disabled
               maxlength="256" placeholder="Datalink URL" id="datalink_url">
      </div>
      <div class="col-12">
        <h3 class="mt-3">QR Code Settings</h3>
      </div>
      <div class="col-12 row">

        <div class="col-6">
          <div>
            <label for="qrCodeSize">Qr Code Size</label>
            {{ qrCodeSize }}
            <input class="w-100" type="range" min="10" max="500" id="qrCodeSize" v-model="qrCodeSize">
          </div>
          <div>
            <label for="qrCodeRender">QR Render Mode</label>
            <b-select v-model="renderQRas" id="qrCodeRender">
              <b-form-select-option value="svg">SVG</b-form-select-option>
              <b-form-select-option value="canvas">Canvas</b-form-select-option>
            </b-select>
          </div>
          <div>
            <label for="qrErrorMode">QR Render Error Level</label>
            <b-select v-model="qrErrorLevel" id="qrErrorMode">
              <b-form-select-option value="L">L - 7%</b-form-select-option>
              <b-form-select-option value="M">M - 15%</b-form-select-option>
              <b-form-select-option value="Q">Q - 25%</b-form-select-option>
              <b-form-select-option value="H">H - 30%</b-form-select-option>
            </b-select>
          </div>
        </div>
        <div class="col-6">
          <label for="datalink_url">QR Code</label>
          <div v-if="linkData.URL">
            <QrcodeVue :value="linkData.URL" :size="qrCodeSize" :level="qrErrorLevel"
                       :render-as="renderQRas" ref="qrCode"/>
            <button v-if="renderQRas==='svg'" @click="downloadSVG">Download SVG</button>
          </div>

          <div v-else>QR Code will appear here.</div>
        </div>

      </div>
    </div>
    <div class="flex-row justify-content-end mt-2">
      <button class="warning" v-show="isDirty" @click="revertChanges">Revert Changes</button>
      <button :disabled="!isDirty || !isDataValid" @click="clickSave">Save</button>
    </div>

  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import * as AlertHelper from '../helpers/AlertHelper'

import QrcodeVue from 'qrcode.vue'

// CODEMIRROR IMPORTS
import 'codemirror/lib/codemirror.css'
// language
import 'codemirror/mode/htmlmixed/htmlmixed.js'
import 'codemirror/mode/javascript/javascript'
// theme css
import 'codemirror/theme/monokai.css'
// require active-line.js
// styleSelectedText
import 'codemirror/addon/selection/mark-selection.js'
import 'codemirror/addon/search/searchcursor.js'
// hint
import 'codemirror/addon/hint/show-hint.js'
import 'codemirror/addon/hint/show-hint.css'
import 'codemirror/addon/hint/javascript-hint.js'
import 'codemirror/addon/selection/active-line.js'
// highlightSelectionMatches
import 'codemirror/addon/scroll/annotatescrollbar.js'
import 'codemirror/addon/search/matchesonscrollbar.js'
import 'codemirror/addon/search/match-highlighter.js'
// keyMap
import 'codemirror/mode/clike/clike.js'
import 'codemirror/addon/edit/matchbrackets.js'
import 'codemirror/addon/comment/comment.js'
import 'codemirror/addon/dialog/dialog.js'
import 'codemirror/addon/dialog/dialog.css'
import 'codemirror/addon/search/search.js'
import 'codemirror/keymap/sublime.js'
// foldGutter
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/addon/fold/brace-fold.js'
import 'codemirror/addon/fold/comment-fold.js'
import 'codemirror/addon/fold/foldcode.js'
import 'codemirror/addon/fold/foldgutter.js'
import 'codemirror/addon/fold/indent-fold.js'
import 'codemirror/addon/fold/markdown-fold.js'
import 'codemirror/addon/fold/xml-fold.js'
import IconSelector from '@/components/shared/IconSelector.vue'

// --------
export default {
  name: 'admin-edit-data-link',
  components: {
    QrcodeVue
  },
  props: {
    link_id: [Number, String]
  },
  data: function () {
    return {
      loading: true,
      showButtons: true,
      linkData: {},
      dataText: null,
      cmOptions: {
        tabSize: 4,
        styleActiveLine: false,
        lineNumbers: true,
        styleSelectedText: false,
        line: true,
        foldGutter: false,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
        highlightSelectionMatches: {showToken: /\w/, annotateScrollbar: true},
        mode: 'text/javascript',
        // hint.js options
        hintOptions: {
          completeSingle: false
        },
        keyMap: 'sublime',
        matchBrackets: true,
        showCursorWhenSelecting: true,
        theme: 'monokai',
        extraKeys: {
          'Ctrl': 'autocomplete',
          'Ctrl-S': () => this.clickSave()
        }
      },
      expandData: false,
      isDirty: false,
      validRelatedTypes: [],
      savedValidRelatedTypes: null,
      activityTypes: [
        {value: 'form', text: 'Form'},
      ],
      qrCodeSize: 300,
      renderQRas: 'svg',
      qrErrorLevel: 'L',
      typeTemplates: {
        form: {
          'device_imei': '',
          'template_id': 4
        }
      }
    }
  },
  async created() {
    if (this.link_id === 'new') {
      this.loadLinkData({
        id: 'NEW',
        owner_id: null,
        name: null,
        activity_type: null,
        published: true,
        data: {}
      })
      this.setSavedLinkData(this.linkData)
    } else if (this.link_id) {
      this.loadLinkData(await this.getLinkData(this.link_id))
      this.setSavedLinkData(this.linkData)
    } else {
      ErrorHelper.displayGeneralErrorToast('Template ID was not provided to Template Editor.',
        'No Template Id')
    }
    this.loading = false
  },
  methods: {
    async getLinkData(linkId) {
      let resp = await DataProvider.adminGetLinkData(linkId)
      console.log('resp": ', resp)
      if (resp.success) {
        return resp.data.link_data
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    loadLinkData(newLinkData) {
      console.log('Loading LinkData: ', newLinkData)
      this.linkData = {...newLinkData}
      this.dataText = JSON.stringify(newLinkData.data, null, '\t')
    },
    setSavedLinkData(newLinkData) {
      this.savedTemplate = {...newLinkData}
      this.savedDataText = JSON.stringify(newLinkData.data, null, '\t')
      this.isDirty = false
    },
    setDirty(newVal) {
      this.isDirty = newVal
    },
    changeData() {
      this.isDirty = true
      console.log('CHANGE')
      try {
        this.linkData.data = JSON.parse(this.dataText)
      } catch (e) {
        // This is fine
      }
    },
    revertChanges() {
      console.log('Reverting...', this.savedTemplate)
      this.loadLinkData(this.savedTemplate)
      this.dataText = this.savedDataText
      this.isDirty = false
    },
    clickSave() {
      if (this.linkData.id === 'NEW') {
        this.createLinkData()
      } else {
        this.updateLinkData()
      }
    },
    getSaveLinkDataObject(isNew = false) {
      let saveLinkData = {...this.linkData}
      if (isNew) {
        saveLinkData.id = null
      }
      if (this.linkData.owner_id === '') {
        saveLinkData.owner_id = null
      }
      if (this.linkData.link_code === '') {
        saveLinkData.link_code = null
      }
      delete saveLinkData.created
      return saveLinkData
    },
    async createLinkData() {
      console.log('Creating Link Data: ', this.linkData)
      let linkDataPayload = this.getSaveLinkDataObject(true)
      let resp = await DataProvider.adminCreateLinkData(linkDataPayload)
      console.log('Response: ', resp)
      if (resp.success) {
        this.loadLinkData(resp.data.link_data)
        this.setSavedLinkData(resp.data.link_data)
        AlertHelper.successToast('Data Link Created Successfully!', 'Data Link Created')
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    async updateLinkData() {
      console.log('Updating Link Data: ', this.linkData)
      let saveLinkData = this.getSaveLinkDataObject(false)

      let resp = await DataProvider.adminEditLinkData(this.linkData.id, saveLinkData)
      console.log('Response: ', resp)
      if (resp.success) {
        this.loadLinkData(resp.data.link_data)
        this.setSavedLinkData(resp.data.link_data)
        AlertHelper.successToast('Link Data Saved Successfully!', 'Link Data Saved')
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    changeType(newType) {
      console.log(newType)
      this.isDirty = true
      if (Object.hasOwn(this.typeTemplates, newType)) {
        this.dataText = JSON.stringify(this.typeTemplates[newType], null, '\t')
      }
    },
    downloadSVG() {
      console.log(this.$refs.qrCode.$el.getElementsByTagName('svg')[0])
      let svgEl = this.$refs.qrCode.$el.getElementsByTagName('svg')[0]
      svgEl.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
      var svgData = svgEl.outerHTML
      var preface = '<?xml version="1.0" standalone="no"?>\r\n'
      var svgBlob = new Blob([preface, svgData], {type: 'image/svg+xml;charset=utf-8'})
      var svgUrl = URL.createObjectURL(svgBlob)
      var downloadLink = document.createElement('a')
      downloadLink.href = svgUrl
      if (Object.hasOwn(this.linkData.data, 'device_imei') && this.linkData.data.device_imei.length) {
        downloadLink.download = `${this.linkData.data.device_imei}.svg`
      } else {
        downloadLink.download = `${this.linkData.link_code}.svg`
      }
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }
  },
  computed: {
    isDataValid() {
      if (this.dataText) {
        try {
          JSON.parse(this.dataText)
          return true
        } catch (e) {
          return false
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../variables';

.admin-edit-template {
  color: $text-color-invert;
  padding: 5px;
}

.toolbar {
  display: flex;
  flex-direction: row;
}

.icon-button, .icon-button-danger {
  font-size: 1.9rem;
}

.notification-item {
  background: $theme-color-background-1;
}

.b-dropdown-form:hover {
  color: white;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(56, 212, 41, 0.25);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.invalid-data {
  outline: 1px solid red;
}

.invalid-warning {
  color: #cc9c00;
}

.vue-codemirror {
  text-align: start;
}

.schema-editor {
  overflow: auto;
}

.expand-section {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100vw - 61px);
  height: 100vh;
  z-index: 50;
  background: $theme-color-background-2;
  overflow: auto;
  padding-bottom: 2em;
}

.expand-editor {
  height: auto;
}

.preview-container {
  margin: 2em auto;
  background: $theme-color-background-2;
  border-radius: 25px;
  max-width: 680px;
  padding: 40px;
}

</style>
